.footer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-content: center;
    color: white;
    bottom: 0;  
  }
  
  .dynamicLogo {
    margin-left: 5px;
    margin-right: 5px;
  }
  .dynamicLogo path{
    fill: #8e8e8e;    
    transition: fill .6s ease;
      transition-property: fill;
      transition-duration: .6s;
      transition-timing-function: ease;
      transition-delay: 0s;
    height: 25px;
  }
  
  .dynamicLogo:hover path {
    fill: rgb(101, 167, 185);
    transition: fill .6s ease;
      transition-property: fill;
      transition-duration: .6s;
      transition-timing-function: ease;
      transition-delay: 0s;
  }

  .logoLinks {
    padding: 0px;
    margin: 0px;
    display:flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-content: center;
  }

  .bottomText {
    display: flex;
    font-size: 12px;
    justify-content: center;
    margin-bottom: 10px;
    color: #8e8e8e;
  }

  @media (min-width: 750px) {
    .footer {
      display:none;
    }
  }