.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.clearfix { display: inline-block; }
/* start commented backslash hack \*/
* html .clearfix { height: 1%; }
.clearfix { display: block; }
/* close commented backslash hack */

body {
  margin: 0px;
}
main {
  margin: 0;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  padding: 0;
}

main::-webkit-scrollbar-track
{
    border-radius: 10px;
    background-color: #bfc2c9;
}

main::-webkit-scrollbar
{
    width: 5px;
    background-color: rgba(233, 223, 223, 0.479);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
}

main::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sideLinks {
  display: none;
}

@media (min-width: 750px){
.sideLinks {
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 350px;
  width: 9%;
  bottom: 0px;
  justify-content: space-between;
  align-items: center;
  left: 0px;
} 
}

line {
  stroke: #8e8e8e;
  stroke-width: 7px;
}
