.dataBlock {
    display:flex;
    width: 80%;
    font-size: 50px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.title {
    color: rgb(101, 167, 185); 
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bottomBorder {
    color: rgb(101, 167, 185);
    width: 75%;
    border-bottom: 2px solid;
}

.linkLogo :first-child{
    fill:none !important;
}

.info {
    margin-top: 50px;
    margin-bottom: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.image {
    border-radius: 10px;
    width: 350px;
    height: 350px;
    margin: 15px;
}

.line {
    -webkit-border: 2px solid rgb(101, 167, 185);
    border: 2px solid rgb(101, 167, 185);
    max-width:400px;
    max-height:300px;
    margin: 15px;
}

.clickable{
    border:2px solid transparent;
    box-shadow: 0px 0px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    transition: transform .4s ease-in-out;
    -webkit-transition: transform .4s ease-in-out;
}
.clickable:hover {
    transition: transform .4s ease-in-out;
    transform: scale(1.05);
    border:2px solid rgb(101, 167, 185);
}

.infoTitle {
    display: flex;
    font-size: .75em;
    width: 300px;
    flex-direction: column;
    margin:15px;
}

.subtitle {
    color: #999999;
    font-size: .5em;
    margin-top: 10px;
}

.emailButton {
    border-radius: 3px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    color: rgb(101, 167, 185);
    font-size: .75em;
    display:flex;
    height: fit-content;
    justify-content: center;
    align-items: center;
    align-self:center;
    justify-self: center;
    background: #8e8e8e;
    transition: background .6s ease;
    text-decoration: none;
    & svg {
        width: 50px;
        height: auto;
        fill:#282c34;
    }
    &:hover {
        transition: background .6s ease;
        background: rgb(101, 167, 185);
        color: #282c34;
        cursor: pointer;
    }
}


.infoText {
    font-size: .5em;
}

.card {
    @media (min-width: 600px) {
        box-shadow: 0px 0px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
        margin-bottom:2rem;
    }
}

.interior {
    width: 100%;
}