.App-header {
    background-color: #282c34;
    color: black;
    width: 100%;
    position: fixed;
    height: 60px;
    z-index: 1;
    display:flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px -1px 13px 10px #20232a;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    top: 0px;
  }
.hideHeader {
  top: -60px;
  transition: top .3s ease-in-out;
}
.headerBigger {
  height: 80px;
  box-shadow:none;
}

.Title {
  align-self:center;
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  }
  
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 50px;
    position: relative;
    pointer-events: none;
  }
  
  .krLogo {
    position:relative;
    height: 50px;
    fill: rgb(101, 167, 185);
  }
  
  .Links {
    padding: 0px;
    margin: 0px;
    justify-self: flex-end;
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    width: 70px;
    margin-left: auto;
  }